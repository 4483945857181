import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import { Link } from 'react-router-dom';
import instaImg from "../assets/img/instagram1.gif";
import simplerPianoImg from "../assets/img/Simpler-Piano.gif";
import projImg2 from "../assets/img/dormbnb.jpg";
import projImg3 from "../assets/img/ytubehome.gif";
import projImg4 from "../assets/img/typingtest.gif";
import projImg5 from "../assets/img/cube.png";
import projImg6 from "../assets/img/pagination.jpg";
import chromeExtImg from "../assets/img/tutorial03.gif";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const projects = [
    {
      title: "Interactive Piano Practice Web Application",
      description: "A web application that allows users to simulate playing the piano with live note visuals.",
      technologies: "Next.js, TypeScript, Preact, Radix UI, tonejs, Fluent-ffmpeg, Skia Canvas, Tailwind CSS, Jest",
      imgUrl: simplerPianoImg,
      address: "https://piano.billy-chiu.com",
      github: "https://github.com/13illydakid/simpler-piano",
    },
    {
      title: "Interactive Rubik’s Cube Simulator",
      // description: "Developed an interactive graphics web application using THREE.js to simulate a 3D Rubik's Cube, allowing users to manipulate and apply different solving algorithms.",
      description: "Designed to improve the user's spatial awareness of all cube faces simultaneously to determine the optimal cube orientation to apply speedcubing algorithms to",
      technologies: "THREE.js, JavaScript, React, BootStrap, Parcel, Eslint, Dat.gui, jQuery, Popper.js",
      imgUrl: projImg5,
      address: "https://cube.billy-chiu.com",
      github: "https://github.com/13illydakid/rubiks-cube",
    },
    {
      title: "Property Management System",
      description: "A React based web application with CRUD functionality that allows users to create, manage, and review home listings.",
      technologies: "React, JavaScript, Express, Node.js, Sequelize, Postgres, Redux",
      imgUrl: projImg2,
      address: "https://dormbnb.billychiu.dev",
      github: "https://github.com/13illydakid/api-design",
    },
    {
      title: "Instagram",
      description: "Built with Next.js, Next-Auth, Recoil, and Firebase v9. User is able to sign in with Google account and upload images directly from the User's system. Includes like and comment functionality. Stories and suggestions side pannel seeded with data from Faker.js",
      technologies: "",
      imgUrl: instaImg,
      address: "https://gallery.billychiu.dev",
      github: "https://github.com/13illydakid/instagram-clone-2.0",
    },
    {
      title: "YoutTube",
      description: "Built a replica of Youtube's homepage with TypeScript, React and Tailwind CSS. Application features responsive components.",
      technologies: "",
      imgUrl: projImg3,
      address: "https://ytubehome.billychiu.dev",
      github: "https://github.com/13illydakid/youtube-homepage",
    },
    // {
    //   title: "Typing Test",
    //   description: "Typing Test application is written in python and is able to evauluate the user's typing input and display it in real time.",
    //   technologies: "",
    //   imgUrl: projImg4,
    //   address: "https://typetest.billychiu.dev",
    //   github: "https://github.com/13illydakid/Typing_Test",
    // },
    {
      title: "Type Test",
      description: "Typing Test application is written in python and is able to evauluate the user's typing input and display it in real time.",
      technologies: "",
      imgUrl: projImg4,
      address: "https://type.billy-chiu.com",
      github: "https://github.com/13illydakid/Type-Test",
    },
    {
      title: "Pagination Chrome Extension",
      description: "Accessibility tool for online shopping. This project creates a Chrome extension that listens for keyboard shortcuts ('Ctrl+N' for next page and 'Ctrl+P' for the previous page) and navigates to the appropriate link based on the selectors provided within the current active tab.",
      technologies: "",
      imgUrl: projImg6,
      address: "",
      github: "https://github.com/13illydakid/Pagination-Chrome-Extension",
    },
  ];
  const projectsTwo = [
    {
      title: "Interactive Piano Practice Web Application",
      description: "A web application that allows users to simulate playing the piano with live note visuals.",
      technologies: "Next.js, TypeScript, Preact, Radix UI, tonejs, Fluent-ffmpeg, Skia Canvas, Tailwind CSS, Jest",
      imgUrl: simplerPianoImg,
      address: "https://piano.billy-chiu.com",
      github: "https://github.com/13illydakid/simpler-piano",
    },
    {
      title: "Interactive Rubik’s Cube Simulator",
      // description: "Developed an interactive graphics web application using THREE.js to simulate a 3D Rubik's Cube, allowing users to manipulate and apply different solving algorithms.",
      description: "Designed to improve the user's spatial awareness of all cube faces simultaneously to determine the optimal cube orientation to apply speedcubing algorithms to",
      technologies: "THREE.js, JavaScript, React, BootStrap, Parcel, Eslint, Dat.gui, jQuery, Popper.js",
      imgUrl: projImg5,
      address: "https://cube.billy-chiu.com",
      github: "https://github.com/13illydakid/rubiks-cube",
    },
    {
      title: "Property Management System",
      description: "A React based web application with CRUD functionality that allows users to create, manage, and review home listings.",
      technologies: "React, JavaScript, Express, Node.js, Sequelize, Postgres, Redux",
      imgUrl: projImg2,
      address: "https://dormbnb.billychiu.dev",
      github: "https://github.com/13illydakid/api-design",
    },
    {
      title: "Type Test",
      description: "Typing Test application is written in python and is able to evauluate the user's typing input and display it in real time.",
      technologies: "",
      imgUrl: projImg4,
      address: "https://type.billy-chiu.com",
      github: "https://github.com/13illydakid/Type-Test",
    },
    // {
      //   title: "Price Monitor",
      //   description: "",
      //   technologies: "",
      //   imgUrl: projImg5,
      //   address: "",
      //   github: "",
      // },
    ];
    const projectsThree = [
      {
        title: "Extension for Chrome",
        description: "All of my Chrome extension repositories combined into one for convenience.",
        technologies: "HTML, CSS, JavaScript",
        imgUrl: chromeExtImg,
        address: "https://github.com/13illydakid/ChromeExtensions-Joined",
        github: "https://github.com/13illydakid/ChromeExtensions-Joined",
      }
    ]

    return (
      <section className="project" id="projects">
      <Container>
        <Row>
        <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Software Projects</h2>
                <p>
                  {/* Here are some of my most memorable projects. */}
                  {/* <br />
                  "infinite trial-plan" is an piano practicing app that is able to play uploaded piano songs, when a keyboard piano is connected through midi, the user can play the notes in real time with the song just like in the classic "guitar hero" games.
                  <br />
                  My favorite option for piano practice is by far using the ipad application called "Simply Piano", while the free plan is limited, the premium package is full of exciting content that helps ensure learning to never be boring. */}
                </p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">
                        <button id="projects-one" >
                          My Most Memorable
                        </button>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">
                        <button id="live-applications" >
                          Live Demo
                        </button>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">
                        <button id="projects-three" >
                          Chrome Extensions
                        </button>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>

                    <Tab.Pane eventKey="second">
                    <div id="live-sites">
                    {/* <p>These projects are live.</p> */}
                    <Row>
                        {
                          projectsTwo.map((project, index) => {
                            return (
                              <ProjectCard
                              key={index}
                              {...project}
                              />
                            )
                          })
                        }
                      </Row>
                        </div>
                    </Tab.Pane>

                    <Tab.Pane eventKey="third">
                      {/* <p>A list of projects I'm currently working on or plan to in the near future.</p> */}
                      <Row>
                        {
                          projectsThree.map((project, index) => {
                            return (
                              <ProjectCard
                              key={index}
                              {...project}
                              />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>

                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2} alt=""></img>
    </section>
  )
}
