import { Col } from "react-bootstrap";
import gitHubIcon from '../assets/img/github.svg';

export const ProjectCard = ({ title, description, technologies, imgUrl, address, github }) => {
  return (
    <Col size={12} sm={6} md={4}>
      <div className="proj-imgbx">
        <img src={imgUrl} alt="" />
        <div className="proj-txtx">
          <div className="proj-links" >
            <span className="social-icon">
              <a href={github} target="_blank" rel="noopener noreferrer"><img src={gitHubIcon} alt="" /></a>
            </span>
          </div>
          <a href={address} class="button" target="_blank" rel="noopener noreferrer">
            <h4>{title}</h4>
          </a>
          {/* <span>{description}</span> */}
          <p>{description}</p>
        <span>{technologies}</span>
        </div>
      </div>
    </Col>
  )
}
