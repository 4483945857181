import { Container, Row, Col } from "react-bootstrap";
import { MailchimpForm } from "./MailchimpForm";
import logo from "../favicon.png";
// import logo from "../assets/img/image.png";
// import logo from "../assets/img/cube.png";
import navIcon1 from "../assets/img/github.svg";
import navIcon2 from "../assets/img/linkedin.svg";
import navIcon3 from "../assets/img/resume.svg";
import Resume from '../assets/Resume.pdf';


export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <MailchimpForm />
          <Col size={12} sm={6}>
            <img src={logo} alt="Logo" />
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              <a href="https://github.com/13illydakid" target="_blank" rel="noopener noreferrer"><img src={navIcon1} alt="" /></a>
              <a href="https://www.linkedin.com/in/billy-h-chiu" target="_blank" rel="noopener noreferrer"><img src={navIcon2} alt="" /></a>
              <a href={Resume} target="_blank" rel="noopener noreferrer"><img src={navIcon3} alt="" /></a>
            </div>
            <p>It's been a Pleasure!</p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
