import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
// import headerImg from "../assets/img/header-img.svg";
import img1 from "../assets/img/logo.png";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = ["Software Engineer"];
  const period = 2000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text])

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(500);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  }

  return (
    <section className="banner" id="home">
      <Container>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <span className="tagline">Welcome to my Portfolio</span>
                  <h1>{`Hello! My name is Billy`} <span className="txt-rotate" dataPeriod="1000" data-rotate='[ "Software Engineer" ]'><span className="wrap">{text}</span></span></h1>

                  {/* <p><br />I am a Full Stack Software Engineer skilled in JavaScript, Python, and a range of modern technologies. Expertise in building robust applications with a focus on user-centric design and seamless functionality. Adept at collaborating within teams to deliver high-quality code and solutions. <br /> <br />
                    I thrive in environments characterizing continuous learning. Passionate about data collection, data analysis, and crafting impactful solutions.<br />&nbsp;</p> */}
                  <p>
                    <br />
                    {/* With a unique background in both science and technology, */}
                    I am a dynamic Software Engineer skilled in both front-end and back-end development. While my education in Physiology and Neuroscience at UC San Diego might surprise some, it's fueled my problem-solving skills and creativity. Being the foundation which I leveraged to further hone my technical prowess through rigorous training at App Academy. Beyond my day job as Software Engineer Intern at Sustainable Living Guide, I'm a prolific coder, building projects such as: an interactive piano practice application and a Rubik's Cube simulator, which showcases my proficiency with advanced technologies such as Next.js, TypeScript, and THREE.js.
                    <br />
                    <br />
                    I excel in leveraging my comprehensive skill set—spanning HTML, CSS3, JavaScript, Python, Node.js, and various database management systems—to develop robust, efficient, and user-friendly web applications. My enthusiastic nature, problem-solving abilities, and commitment to continuous learning are assets I strive to bring to any development team.
                    <br />
                    &nbsp;
                  </p>
                  <a href="#personal-bios" >
                    <button onClick={() => console.log('connect')}>Get to Know Me. <ArrowRightCircle size={25} /></button>
                  </a>

                </div>}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                  <img src={img1} alt="Header Img" />
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
