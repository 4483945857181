import 'react-multi-carousel/lib/styles.css';
// import arrow1 from "../assets/img/arrow1.svg";
// import arrow2 from "../assets/img/arrow2.svg";
// import colorSharp from "../assets/img/color-sharp.png"
import { useState } from "react";

export const PersonalBio = () => {
  const [isVisibleOne, setIsVisibleOne] = useState(false);
  const [isVisibleTwo, setIsVisibleTwo] = useState(false);
  const [isVisibleThree, setIsVisibleThree] = useState(false);
  const [isVisibleFour, setIsVisibleFour] = useState(false);


  return (
    <section className="personal-bio" id="personal-bios">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="personal-bio-bx wow zoomIn">
              <h2>Get to Know Me.</h2>
              <p>
                If I could travel back in time, I would repeat the same actions as before to see if the outcome will be the same. If I could go back a second time, I would want my memories erased accordingly to see if I would make the same actions as before.
              </p>
              <p> My hobbies include: <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;- Playing Piano (my favorite genre is Symphonic metal) <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;- Speed Cubing (checkout my interactive Rubik's cube simulator site! Go to projects)<br />
                  &nbsp;&nbsp;&nbsp;&nbsp;- Duolingo! The leading language learning app designed by learning experts. I'm learning Japanese! <br />
                  {/* &nbsp;&nbsp;&nbsp;&nbsp;- <br /> */}
                  <br />
                  Check out my Utility Chrome Extension: "https://github.com/13illydakid/ChromeExtensions-Joined". <br />
                  (It's an incredible time-saver, especially if & when you are "Online Shopping" or "Job Searching".)
                  {/* As a child I never believed in Santa. Instead I believed that puberty occured overnight, so each summer
                  I would be anxious over the possible scenario where I wouldn't be able to fit the clothes I packed for an annual week-long Bible Camp. */}

                <br />
                <br />
                {/* I believe that negativity is overly stigmatized in this time period and people sigh a lot more than they realize but it is masked with a word or phrase when sighing. */}
                <br />
                <br />
              {/*
                I often watch anime during my free time though I'm unsure to categorize this as a hobby because this has been true for me since the age of 4. <br />
                Three notable habits that I exhibit when watching anime, for relevance in providing keener insight into my personality: <br />
                &nbsp;&nbsp;&nbsp;&nbsp; 1. watching a series non-sequentially.&nbsp;
                <br />
                <span style={isVisibleOne ? {} : { display: 'none' }} >
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Every series that I have seen was first introduced to me through various forms of advertisement such as clips
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                from; youtube suggestions, facebook & instagram reels/tags, memes, etc...
                </span>
                <button onClick={() => setIsVisibleOne(!isVisibleOne)}>
                  <h3 style={isVisibleOne ? { display: 'none' } : {}} >...</h3>
                </button>
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp; 2. pausing an episode to look up the voice actor of a particular character and find out which characters are voiced by the same actor
                <br />
                <span style={isVisibleTwo ? {} : { display: 'none' }} >
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                It brings me incredible joy to hear the voice of a character from a series that is finished rekindled as another.
                </span>
                <button onClick={() => setIsVisibleTwo(!isVisibleTwo)}>
                  <h3 style={isVisibleTwo ? { display: 'none' } : {}} >...</h3>
                </button>
                <br />
                2. pausing an episode to look up characters in other animes that share the same voice actor.
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp; 3. the more I like an anime series the longer it'll take for me to get through it.
                <br />
                <span style={isVisibleThree ? {} : { display: 'none' }} >
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                I call it rationing
                </span>
                <button onClick={() => setIsVisibleThree(!isVisibleThree)}>
                  <h3 style={isVisibleThree ? { display: 'none' } : {}} >...</h3>
                </button>
                <br />
                <br />
              */}
                {/* <h3>Questions I would ask you.</h3>
                &nbsp;&nbsp;&nbsp;&nbsp; - What is your favorite font?
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp; - Do you prefer making your passwords random(password managers count) or you like to add a bit of culture to it?
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp; - Are you currently using an iPhone or Android? And have you ever tried the ever? If yes, why did you switch?
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp; - How long have you had your current hairstyle?
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp; - Do you prefer having your phone in your right or left pocket? What about wallet & keys?
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp; - What browser or browsers do you use?
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp; - Do you prefer online multiplayer games (Fortnite, Call of Duty, etc.) or rpg games (Pokemon, Elden Ring, Nintendo Switch: The Legend of Zelda, Grand Theft Auto, etc.)?
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp; -
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp; -
                <br /> */}

                {/* I wear swimming-trunks as regular shorts which was not intentional at first. I never learned to swim and did not know what swimming-trunks were. */}

              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <img className="background-image-left" src={colorSharp} alt="Image" /> */}
    </section>
  )
}
