import 'react-multi-carousel/lib/styles.css';
// import arrow1 from "../assets/img/arrow1.svg";
import jsImg from "../assets/img/javascript-logo.svg"
import pythonImg from "../assets/img/python.svg";
import htmlImg from "../assets/img/html5.svg";
import cssImg from "../assets/img/css.svg";
import reactImg from "../assets/img/react-1.svg";
import reduxImg from "../assets/img/redux.svg";
import tableauImg from "../assets/img/tableau.svg";
import jupyterImg from "../assets/img/jupyter.svg";
import pandasImg from "../assets/img/pandas.svg";
import flaskImg from "../assets/img/flask.png";
import expressImg from "../assets/img/express-logo.png";
import sequelizeImg from "../assets/img/sequelize.svg";
import sqliteImg from "../assets/img/sqlite.svg";
import dockerImg from "../assets/img/docker.svg";
import tailwindImg from "../assets/img/tailwind-css.svg";
import bootstrapImg from "../assets/img/bootstrap.svg";
import nodeImg from "../assets/img/node-js.svg";
import firebaseImg from "../assets/img/firebase.svg";
import postgresImg from "../assets/img/postgresql.svg";
import mochaImg from "../assets/img/mocha.svg";
import jestImg from "../assets/img/jest.svg";
import threeImg from "../assets/img/threejs.svg";
import numpyImg from "../assets/img/numpy-1.svg"
import numpyImgTwo from "../assets/img/numpy-2.svg"
import opencvImg from "../assets/img/opencv.svg";
import condaImg from "../assets/img/conda.svg";
import mysqlImg from "../assets/img/mysql.svg";
import postmanImg from "../assets/img/postman.svg";
import wordpressImg from "../assets/img/wordpress.svg";
import figmaImg from "../assets/img/figma.svg";
import asanaImg from "../assets/img/asana.svg";
import canvaImg from "../assets/img/canva.svg";
// import logoImg from "../assets/img/favicon.svg";
// import logoImgTwo from "../assets/img/logo(non-transparent).svg";
// import logoImgThree from "../assets/img/logo(transparent).svg";
// import Img from "../assets/img/";
// import arrow2 from "../assets/img/arrow2.svg";
import colorSharp from "../assets/img/color-sharp.png"

export const Skills = () => {

  const skillsArray = [
    { title: "JavaScript", imgUrl: jsImg, },
    { title: "Python", imgUrl: pythonImg, },
    { title: "HTML5", imgUrl: htmlImg, },
    { title: "CSS3", imgUrl: cssImg, },
    { title: "React", imgUrl: reactImg, },
    { title: "Redux", imgUrl: reduxImg, },
    { title: "Tableau", imgUrl: tableauImg, },
    { title: "Jupyter", imgUrl: jupyterImg, },
    { title: "Pandas", imgUrl: pandasImg, },
    { title: "Flask", imgUrl: flaskImg, },
    { title: "Express.js", imgUrl: expressImg, },
    { title: "Sequelize", imgUrl: sequelizeImg, },
    { title: "SQLite", imgUrl: sqliteImg, },
    { title: "Docker", imgUrl: dockerImg, },
    { title: "Tailwind CSS", imgUrl: tailwindImg, },
    { title: "Bootstrap", imgUrl: bootstrapImg, },
    { title: "Node.js", imgUrl: nodeImg, },
    { title: "Firebase", imgUrl: firebaseImg, },
    { title: "PostgreSQL", imgUrl: postgresImg, },
    { title: "Mocha", imgUrl: mochaImg, },
    { title: "Jest", imgUrl: jestImg, },
    { title: "Three.js", imgUrl: threeImg, },
    { title: "Numpy", imgUrl: numpyImg, },
    { title: "Numpy", imgUrl: numpyImgTwo, },
    { title: "OpenCV", imgUrl: opencvImg, },
    { title: "Conda", imgUrl: condaImg, },
    { title: "MySQL", imgUrl: mysqlImg, },
    // { title: "non-transparent", imgUrl: logoImgTwo, },
    // { title: "transparent", imgUrl: logoImgThree, },
    // { title: "Postman", imgUrl: logoImg, },
    { title: "Postman", imgUrl: postmanImg, },
    { title: "WordPress", imgUrl: wordpressImg, },
    { title: "Figma", imgUrl: figmaImg, },
    { title: "Asana", imgUrl: asanaImg, },
    { title: "Canva", imgUrl: canvaImg, },
    // { title: "Divi Builder", imgUrl: postmanImg, },
    // { title: "", imgUrl: arrow1, },
    // { title: "", imgUrl: arrow1, },
    // { title: "", imgUrl: arrow1, },
    // { title: "", imgUrl: arrow1, },
  ];

  return (
    <section className="skill" id="skills">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="skill-bx wow zoomIn">
                    <h2>Technologies</h2>
                        <p>I am experienced with the following:</p>
                        <div className="skill-card-container" >
                          {
                            skillsArray.map((skill, index) => {
                              return (
                                <div className="skill-card">
                                  <img src={skill.imgUrl} alt="" />
                                  <h4>{skill.title}</h4>
                                </div>
                              )
                            })
                          }
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <img className="background-image-left" src={colorSharp} alt="" />
    </section>
  )
}
